.userPostsContent {
  padding: 30px;
  background-color: #212529;
  overflow-y: auto !important; 
  max-height: 100vh;
}

.userPostsContent::-webkit-scrollbar {
  display: block;
  width: 10px; /* Adjust the width as needed */
}

.userPostsContent::-webkit-scrollbar-thumb {
  background-color: darkgrey; /* Or any color that fits your design */
  border-radius: 10px;
}

.userPostsContent::-webkit-scrollbar-track {
  background-color: #2e2e2e; /* Or any color that fits your design */
}

section {
  padding-top: 20px;
}


.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr)); /* Adjusted min-width */
  grid-gap: 7px;
  justify-content: center;
}