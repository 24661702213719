.chatBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #212529;
  font-family: 'Helvetica', 'Arial', sans-serif;
  overflow: hidden;
}

.chatFooter {
  display: flex;
  align-items: center;
  padding: 10px;

  border-top: 1px solid #ccc;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 1300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.messageInputContainer {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: #ffffff;
  border-top: 1px solid #ccc;
}


.messageInputContainer > textarea {
  flex: 1;
  border: none;
  outline: none;
  padding: 12px;
  font-size: 16px;
  border-radius: 20px;
  background-color: #e5e5ea;
  margin-right: 12px;
  height: auto;
  max-height: 140px;
  overflow: hidden;
  resize: none;

}

.sendButton {
  border: none;
  background-color: inherit;

}

.chatDisclaimer {
  background-color: rgb(241,243,244);
  padding: 0.75rem;
  margin: 0.75rem;
  text-align: center;
  border: 8px;
  font-size: 0.75rem;
  line-height: 1rem;
  border-radius: 8px;
  letter-spacing: .025em;
  user-select: none;
  color: rgb(32,33,36);
  font-family: Arial,sans-serif;
}

.messages::-webkit-scrollbar {
  display: none;
  width: 10px;
}

/* Track */
.messages::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: transparent;
}

/* Handle */
.messages::-webkit-scrollbar-thumb {
  /* -webkit-border-radius: 10px; */
  border-radius: 10px;
  background: #808080; 
  border-right: none;
  border-left: none;
}

.messages::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px; 
}

.messages::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}
.messages::-webkit-scrollbar-thumb:window-inactive {
  display: none;
}