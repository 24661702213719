.shopContainer {
  padding: 30px;
  background-color: #212529;
  overflow-y: auto !important; 
  max-height: 100vh;
}

/* Shop.module.css */
.shopContainer::-webkit-scrollbar {
  display: block;
  width: 10px; /* Adjust the width as needed */
}

.shopContainer::-webkit-scrollbar-thumb {
  background-color: darkgrey; /* Or any color that fits your design */
  border-radius: 10px;
}

.shopContainer::-webkit-scrollbar-track {
  background-color: #2e2e2e; /* Or any color that fits your design */
}

section {
  padding-top: 20px;
}

.shopContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  width: 100%;
  margin-bottom: 20px; /* Reduced bottom margin */
}

.section h2 {
  color: #fff;
  margin-bottom: 15px; /* Reduced bottom margin */
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr)); /* Adjusted min-width */
  grid-gap: 7px;
  justify-content: center;
}

@media (max-width: 768px) {
  .cardContainer {
    grid-template-columns: repeat(2, minmax(0, 1fr)); /* Adjusts to 2 columns */
  }
}

/* Default style for the 'Saved Posts' text */
.savedPostsText {
  color: white;
  margin-left: 17px;
  margin-right: -10px;
  font-weight: bold;
  font-size: 13px;
}

/* Media query for medium-sized devices (e.g., tablets) */
@media (max-width: 768px) {
  .savedPostsText {
    /* Adjust font size, margins, etc., for medium-sized screens */
    font-size: 12px;
  }
}

/* Media query for small devices (e.g., mobile phones) */
@media (max-width: 480px) {
  .savedPostsText {
    /* Adjust font size, margins, etc., for small screens */
    font-size: 12px;
  }
}

.savedPostsContainer {
  padding: 3px;
  padding-left: 0px;
  margin-right: -27px;
  background-color:darkgreen;
  border-radius: 7%;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for background color and shadow */
}

.savedPostsContainer:hover {
  background-color: #005f00; /* Darker shade of green on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow effect */
}

.nothingFoundGif {
  position: fixed;
  top: 12%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 200px;
  height: auto;
  z-index: 1000;
}