/* h1{
  font-family: 'Chalkboard SE', 'Comic Sans MS';
  font-weight: 800 !important;
  text-transform: lowercase;
}

h2{
  font-family: 'Chalkboard SE', 'Comic Sans MS';
  font-weight: 800 !important;
  text-transform: lowercase;
}

h4{
  font-family: 'Chalkboard SE', 'Comic Sans MS';
  font-weight: 800 !important;
  text-transform: lowercase;
}


p{
  font-family: 'Chalkboard SE', 'Comic Sans MS';
} */

.landingPage {
  background-color: 212529;
  height: 100vh;
  overflow-y: auto;
}

.landingPage::-webkit-scrollbar {
  display: block; 
}

.landingPage::-webkit-scrollbar {
  width: 10px; /* Adjust the width of the scrollbar as needed */
}

.landingPage::-webkit-scrollbar-track {
  background: #f0f0f0; /* Light grey track */
}

.landingPage::-webkit-scrollbar-thumb {
  background: #888; /* Darker grey thumb */
  border-radius: 5px;
}

.landingPage::-webkit-scrollbar-thumb:hover {
  background: #555; /* Even darker grey when hovered */
}

.maxWidth{
  width: 100%;
}

.laptop{
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 700px;
  border-radius: 1.5%;
  padding: 20px;
  background-color: #212529;
}

@media only screen and (max-width: 600px) {
  .laptop {
    width: 90%;
  }
}

@media only screen and (max-width: 425px){
  .fortyFivePercent{
    height: 590px;
  }
  .fiftyFivePercent{
    padding-top: 10px;
  }
  #landingSlogan{
    line-height: 60px;
    font-size: 40px;
  }
  #ourStory{
    line-height: 20px;
    font-size: 20px;
    padding-left: 35px;
    margin-top: -20px;
  }

  .footerText{
    padding-top: 0px;
    padding-bottom: 20px;
    padding-left: 30px;
    display: block;
  }
  .legalPageContent{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 1250px){
  .fortyFivePercent{
    width: 100%;
  }
  .fiftyFivePercent{
    width: 100%;
  }
}

/*Below are the animation keyframes for the animated gradient background*/
@-webkit-keyframes Gradient {
  0%{background-position:14% 0%}
  50%{background-position:87% 100%}
  100%{background-position:14% 0%}
}
@-moz-keyframes Gradient {
  0%{background-position:14% 0%}
  50%{background-position:87% 100%}
  100%{background-position:14% 0%}
}
@-o-keyframes Gradient {
  0%{background-position:14% 0%}
  50%{background-position:87% 100%}
  100%{background-position:14% 0%}
}
@keyframes Gradient {
  0%{background-position:14% 0%}
  50%{background-position:87% 100%}
  100%{background-position:14% 0%}
}
