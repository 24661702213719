.postItemDialog {
  background-color: #212529;
  overflow-y: auto !important; 
  max-height: 100vh;
}

.postItemDialog::-webkit-scrollbar {
  display: block;
  width: 10px; /* Adjust the width as needed */
}

.postItemDialog::-webkit-scrollbar-thumb {
  background-color: darkgrey; /* Or any color that fits your design */
  border-radius: 10px;
}

.postItemDialog::-webkit-scrollbar-track {
  background-color: #2e2e2e; /* Or any color that fits your design */
}
