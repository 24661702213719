.productCard {
  width: 100%;
  max-width: 400px;
  margin: 7px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #2e2e2e;
  color: #fff;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.productCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.imageSlide {
  outline: none;
}

.imageSlider {
background-color: white;
padding: 20px;
}

.productImage {
  width: 100%;
  height: 240px; /* Set a fixed height */
  object-fit: contain; /* Ensures images cover the area uniformly */
  border-radius: 15px;
}

.cardContent {
  padding-top: 10px;
}

.cardTitle {
  font-size: 16px;
  font-weight: bold;
  /* padding: 10px; */
  margin-top: 10px;
  margin-bottom: 5px;
}

.cardPrice {
  font-size: 13px;
  color: #4CAF50; /* Green color for price */
  margin-bottom: 10px;
  font-weight: bold;
}

.cardDescription {
  font-size: 15px;
  margin-bottom: 10px;
  line-height: 1.4;
  color: #B0B0B0;
  }
  
  .cardContact {
    display: inline-block;
    margin-top: 5px;
    margin-right: 10px;
    text-decoration: none;
    font-size: 12px; /* Reduced font size */
  }
  
  .instagramIcon {
    display: inline-block;
    font-size: 0.9em; /* Adjust size as needed */
    cursor: pointer; /* Indicates it's clickable */
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
    margin-left: 10px;
  }
  
  .instagramIcon:hover {
    transform: scale(1.1); /* Slightly increase size on hover */
  }
  
  @media (max-width: 480px) {
  .productCard {
  max-width: 100%;
  }
  
  .productImage {
  height: 200px; /* Adjust height for smaller screens */
  border-radius: 10px;

  }
  }

  .modalContent {
    position: fixed; /* Changed from absolute for better centering */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%; /* Increase modal width */
    max-width: 700px; /* Set a max-width */
    max-height:100vh; /* Maximum height */
    overflow-y: auto; /* Enable vertical scrolling */
    border-radius: 10px; /* Optional, for rounded corners */
    outline: none;
    padding: 7px;
  }
  
  /* Adjusting the image size within the modal for larger display */
  .modalContent .productImage {
    height: auto; /* Adjust for larger images */
    max-height: 400px; /* Maximum height for images */
    width: 100%;
    border-radius: 10px;
  }

  .contactLink {
    display: inline-flex; /* Changed from flex to inline-flex */
    align-items: center;
    cursor: pointer;
    color: #007bff; /* Or any color you prefer */
    font-size: 14px;
    text-decoration: none; /* Removes underline */
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
    margin-right: 5px;
  }

  .contactLink:hover {
    transform: scale(1.1);
  }

  .contactInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .postedBy {
    font-size: 13px;
    color: gray;
    margin-top: 14px;
  }

  .postedAt {
    font-size: 11px;
    color: gray;
    margin-top: 1px;
  }

  @media (max-width: 768px) {
  .productCard {
    max-width: calc(120% - 14px); /* Adjust the width to fit 2 items per row */
  }

  .productImage {
    height: 140px; /* Adjust height for smaller screens */
  }
}


@media (max-width: 480px) {
  .productCard {
    max-width: calc(120% - 14px); /* Adjust the width to fit 2 items per row */
  }

  .productImage {
    height: 140px; /* Further adjust height for smaller screens */
  }
}

/* Example with more specific selector */
div.productCard .slick-dots li button {
  background-color: #bbb !important; /* Custom color of dots */
}

div.productCard .slick-dots li.slick-active button {
  background-color: #fff !important;
}

/* Hover effect */
div.productCard .slick-dots li button:hover {
  background-color: #ddd !important;
}

.modalContainer {
  position: relative;
  z-index: 100;
}

.cancelButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: red;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 101;
  transform: none !important;
}


