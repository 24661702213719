/* videoStyles.css */

.local-participant,
.remote-participant {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .local-participant {
    position: absolute;
    top: 0;
    right: 0;
    width: 25%;
    height: 25%;
    z-index: 1;
  }
}
