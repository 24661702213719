.userMessage {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5em;
  padding: 0.5em;
  border-radius: 0.7em;
  background-color: #1982FC;
  display: inline-block;
  word-break: break-word;
  white-space: pre-wrap;
  margin-left: auto;
  animation: fadeIn 0.5s ease-out forwards;
}

.partnerMessage {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5em;
  padding: 0.5em;
  border-radius: 0.7em;
  background-color: #8e8e93;
  display: inline-block;
  word-break: break-word;
  white-space: pre-wrap;
  margin-right: auto;
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
