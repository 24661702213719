/* Overall card styling for a more modern and clean look */
.editableProductCard {
  border: 1px solid rgb(59, 59, 59); /* Light border for subtle definition */
  border-radius: 8px; /* Rounded corners for a softer look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  background-color: gray; /* White background for cleanliness */
  padding: 20px; /* Spacing inside the card */
  margin: 10px 0; /* Space between cards */
  transition: box-shadow 0.3s ease; /* Smooth transition for hover effects */
  max-width: 420px;
  max-height: 100%;
}

.editableProductCard:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover for interactivity */
}

.imageSlide {
  max-height: 400px; /* Adjust based on your preference */
  overflow: hidden;
  display: flex;
  align-items: center; /* Align vertically */
}

/* Image slider customization */
.imageSlide img {
  max-height: 100%;
  max-width: 100%; /* Ensure images are fully visible within the container */
  object-fit: contain; /* Cover the area without losing aspect ratio */
  border-radius: 4px;
}

/* Styling the remove button to be more modern */
.removeImageButton {
  background-color: #ff4d4f; /* Bright color for attention */
  color: black; /* White text for contrast */
  border: none; /* Remove default border */
  padding: 5px 10px; /* Padding for a larger click area */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Cursor indication for clickable */
  transition: background-color 0.2s ease; /* Smooth background color transition */
}

.removeImageButton:hover {
  background-color: #ff7875; /* Lighter shade on hover for feedback */
}

.editingState > span > textarea, .editingState > span > input[type="text"] {
  width: 100%; /* Full width to fit card */
  padding: 10px; /* Comfortable padding */
  margin: 10px 0; /* Space between fields */
  border: 1px solid #e1e1e8; /* Subtle border */
  border-radius: 4px; /* Match other elements with rounded corners */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Button styling for a modern look */
.editButtons > button {
  background-color: #1890ff; /* Pleasant blue */
  color: white; /* Contrast with the button color */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Sufficient padding for ease of click */
  margin-right: 10px; /* Space between buttons */
  border-radius: 4px; /* Consistent rounded corners */
  cursor: pointer; /* Indicate clickable */
  transition: background-color 0.2s ease; /* Smooth transition for visual feedback */
  margin-top: 10px;
}

.editButton > button:hover {
  background-color: #8dacc5; /* Lighter blue on hover */
}

/* Adjusting EditIcon button to align with modern design */
.editIcon {
  cursor: pointer; /* Indicate that it's clickable */
  color: #1890ff; /* Matching color theme */
  transition: color 0.2s ease; /* Smooth transition for hover effect */
}

.editIcon:hover {
  color: #40a9ff; /* Lighter shade for hover effect */
}

.editableProductImage {
  width: 100%;
  height: 240px; /* Set a fixed height */
  object-fit: contain; /* Ensures images cover the area uniformly */
  border-radius: 15px;
}

@media (max-width: 480px) {
  .editableProductCard {
  max-width: 100%;
  }
  
  .editableProductImage {
  height: 200px; /* Adjust height for smaller screens */
  border-radius: 10px;

  }
  }

  .cardPrice {
    font-size: 13px;
    color: #4CAF50; /* Green color for price */
    margin-bottom: 10px;
    font-weight: bold;
  }

  .deleteButton {
    background-color: #ff4d4d; /* Red color */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    margin-left: 10px; /* Adjust based on layout */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease;
  
    /* Improving accessibility */
    outline: none;
  }
  
  .deleteButton:hover,
  .deleteButton:focus {
    background-color: #cc0000; /* Darker shade of red on hover/focus */
  }
  
  .deleteButton:active {
    background-color: #990000; /* Even darker on click */
  }
  

  