.about {
  margin-left: 30px;
  margin-right: 30px;
  max-height: 100vh;
  overflow-y: auto;
}

.imageWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  width: 320px;
  position: relative;
}

.imageContainer img {
  display: block;
  width: 100%;
  padding: 20px;
  height: auto;
  border-radius: 7%;
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  margin: 0;
  font-size: 14px;
  text-align: center;
}

.htmlContent *:not(a) {
  color: #D3D3D3;
}