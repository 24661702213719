.loading, .error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loadingText {
  color: white;
  margin-top: 15px;
}

.videoContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
}

.videoFrame {
  position: relative;
  width: 50%;
  height: 100%;
  padding: 10px;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 15px;
  object-fit: cover;
}

@media screen and (max-width: 1220px) {
  .videoContainer {
    flex-direction: column;
    height: 80vh;
  }

  .videoFrame {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .videoContainer {
    flex-direction: column;
    height: 50vh;
  }

  .videoFrame {
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .videoContainer {
    flex-direction: column;
    height: 40vh;
  }

  .videoFrame {
    width: 100%;
  }
}