@keyframes shimmer {
  0% {
    background-position: -478px 0;
  }
  100% {
    background-position: 478px 0; 
  }
}

.skeletonCard {
  animation-duration: 3.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, 
    #2e2e2e 0%, 
    #3a3a3a 30%, 
    #2e2e2e 50%,
    #3a3a3a 70%, 
    #2e2e2e 100%);  background-size: 800px 104px;
  height: 200px;
  position: relative;
  margin: 10px; /* Adjust the margin as needed */
  border-radius: 5px; /* Optional: for rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: for adding a subtle shadow */
  margin: 0; /* Remove individual margins as the grid gap will handle spacing */

}

.skeletonCard > div {
  background-color: transparent !important;
}

.skeletonLoader {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)); /* Creates a responsive grid */
  gap: 10px; /* Adjust the space between cards */
  padding: 10px; /* Add some padding around the grid */
}
