.socials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  margin-bottom: 4px; /* Add this line */
}

.iconWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.iconWrapper a {
  flex: 1;
  display: flex;
  justify-content: center;
}

.centeredText {
  text-align: center;
  padding-top: 8px;
  margin-bottom: 4px; /* Add this line */
}

.centeredButtons {
  display: flex;
  justify-content: center;
  padding-top: 8px;
  margin-bottom: 4px; /* Add this line */

}

.cardActions {
  padding-right: 8px;
}
