.profile {
  margin-left: 30px;
  margin-right: 30px;
}

.loading, .error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.iconContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%; 
  margin-top: 20%;
  width: 100%;
}