.serviceCardContainer {
  width: 100%;
  position: relative;
  max-width: 350px;
  margin-bottom: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.serviceCardContainer:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.activeDot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
}

.serviceImage {
  width: 100%;
  height: 230px;
  border-radius: 15px;
  padding: 20px;
  object-fit: contain !important;
  outline: none;
} 

.serviceImageModal {
  width: 100%;
  height: 250px;
  border-radius: 15px;
  padding: 20px;
  object-fit: contain !important;
} 

.serviceDescription {
  padding: 4px;
}

/* .subCategoryTag {
  margin-top: 4px;
  margin-bottom: 10px;
  background-color: gray;
} */

.contactLink {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #007bff;
  font-size: 14px;
  text-decoration: none;
  transition: transform 0.3s ease;
  margin-right: 5px;
  padding: 2px;
}

.contactLink:hover {
  transform: scale(1.1);
}

.contactInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modalBoxStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: 530px;
  /* bgcolor: background.paper;
  boxShadow: 24;
  p: 4; */
}

.postedBy {
  font-size: 14px;
  color: gray;
  padding-top: 5px;
}

.postedAt {
  font-size: 12px;
  color: gray;
}

.cardPrice {
  font-size: 13px;
  color: #4CAF50; /* Green color for price */
  margin-bottom: 10px;
  font-weight: bold !important;
}

.cardContact {
  text-decoration: none;
  font-size: 13px;
}

.contactNumber {
  font-size: 10px;
}

@media (max-width: 480px) {
  .serviceCardContainer {
    max-width: 100%;
  }
  
  .serviceImage {
  height: 190px; /* Adjust height for smaller screens */
  border-radius: 10px;
  }
  }

  @media (max-width: 480px) {
    .serviceDescription {
      font-size: 11.5px; /* Smaller font size for mobile */
    }
    .contactLink, .cardContact, .instagramIcon {
      font-size: 10px; /* Adjust icon and text sizes */
    }

    .cardServiceType, .cardPrice {
      font-size: 10.5px !important; /* Smaller font size for mobile */
    }

    .cardPrice {
      margin-left: -20px;
      margin-top: 2px;
    }

  }
  