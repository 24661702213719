.loginContainer {
  font-family: 'Raleway', sans-serif;
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh; 
}

.loginButton {
  height: 100px;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.disclaimerText {
  width: 300px;
  text-align: center;
  font-size: small;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: white;
}

.termsOverlay {
  width: 300px;
  margin-top: 1px;
  text-align: center;
  font-size: small;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: white;
}

.signinInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

body {
  background: #212529;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.overlay {
  background-color: transparent;
  
}
.centeredContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.logoContainer {
  margin-bottom: 5%;
  /* padding: 20px; */
}

.logoText {
  text-align: center;
  color: white;
  font-family: Chalkboard SE;
}

.collegeOptions {
  margin: 10px;
  border: #3f51b5;
  border-radius: 10px;
  display: flex; /* Enable Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex-direction: column; /* Stack children vertically */
}
