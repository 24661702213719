.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  height: 100vh;
  overflow-y: hidden;
}

.loading, .error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3vh;
  margin-top: 10px;
  width: 100%;
}

.videoContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
}

.videoFrame {
  position: relative;
  width: 50%;
  height: 100%;
  padding: 10px;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 15px;
  object-fit: cover;
  transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg); /* Safari and Chrome */
  -moz-transform:rotateY(180deg); /* Firefox */
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 20px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

@media screen and (max-width: 1220px) {
  .videoContainer {
    flex-direction: column;
    height: 80vh;
  }

  .videoFrame {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .videoContainer {
    flex-direction: column;
    height: 50vh;
  }

  .videoFrame {
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .videoContainer {
    flex-direction: column;
    height: 40vh;
  }

  .videoFrame {
    width: 100%;
  }
}

.glow {
  animation: glowEffect 2s ease-in-out forwards;
}

@keyframes glowEffect {
  0%, 100% { text-shadow: 0 0 8px #fff; }
  50% { text-shadow: 0 0 20px #fff; }
}

.star {
  position: absolute;
  color: yellow;
  font-size: 10px; 
  opacity: 0;
  animation: sprinkleStar 2s linear forwards;
}

.star::before, .star::after {
  content: '\2605';
  position: absolute;
  top: 0;
}

@keyframes sprinkleStar {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5) translate(20px, 20px); /* Adjust as needed */
  }
}
